import footerHTML from '../html/brighthouse';
import cssVariables from '../css/bh';
import ssoConfig from './sso';

const brighthouse = {
	global: {
		appTitle: 'Brighthouse Financial | Acquire',
		favicon: '',
		homepage: '/',
		carrierName: 'Brighthouse Financial.',
		loader: '/images/animation_loading.webp',
	},
	branding: {
		lightLogo: '/images/brightHouse_financial_ahead_logo.svg',
		darkLogo: '/images/brightHouse_financial_ahead_logo.svg',
		altText: 'Brighthouse Financial',
		link: 'https://www.brighthousefinancial.com/',
	},
	footer: {
		logo: '/images/brighthouse-financial_tag.svg',
		altText: 'Brighthouse Financial',
		isHTML: false,
		footerHTML,
		phoneText: '',
		phoneNumber: '',
		disclaimerHead: '© 2021 Brighthouse Financial, Inc.',
		disclaimerDesc: 'Brighthouse Financial® and its design are registered trademarks of Brighthouse Financial, Inc. and/or its affiliates. Brighthouse Financial is the brand name for Brighthouse Life Insurance Company, Brighthouse Life Insurance Company of NY, and New England Life Insurance Company.',
		supportPhoneNumber: '',
		socialLinks: [],
		footerLinksTop: [
			{
				id: 'accessibility',
				linkText: 'Accessibility',
				linkUrl: 'https://www.brighthousefinancial.com/support/accessibility/',
			  },
			  {
				id: 'manage_your_preferences',
				linkText: 'Manage Your Preferences',
				linkUrl:
				  'https://www.brighthousefinancial.com/manage-your-preferences/',
			  },
			  {
				id: 'life_insurance_policy_locator',
				linkText: 'Unclaimed Policy Finder',
				linkUrl:
				  'https://www.brighthousefinancial.com/life-insurance-policy-locator/',
			  },
			  {
				id: 'privacy_policy',
				linkText: 'Privacy Statement',
				linkUrl: 'https://www.brighthousefinancial.com/privacy-policy/',
			  },
			  {
				id: 'terms_of_use',
				linkText: 'Terms of Use ',
				linkUrl: 'https://www.brighthousefinancial.com/terms-of-use/',
			  },
			  {
				id: 'domestic_violence',
				linkText: 'For Victims of Domestic Violence',
				linkUrl: 'https://www.brighthousefinancial.com/domestic-violence/',
			  },
			  {
				id: 'report_fraud',
				linkText: 'Fraud Reporting',
				linkUrl: 'https://www.brighthousefinancial.com/report-fraud/',
			  },
		],
		footerLinksBottom: [],
	},
	theme: {
		cssVars: cssVariables,
		global: {
			colorScheme: 'fern',
			background: '#f8f8f8',
      		leftSidebarColor: '#fff',
			footerBackground: '#f8f8f8',
			footerTextColor: '#4a4844',
			showPageInfo: true,
      		mergeActionPageButtons: false,
			overlayBgColor: 'rgba(59, 83, 105, 1)',
			errorMessageColor: 'rgb(201, 68, 54)',
			breadcrumb: {
				active: {
				  progressLabelColor: '#0B465A',
				  titleColor: 'black',
				  background: '#ffffff',
				  imageOpacity: '1',
				  border: '1px solid rgb(130, 130, 130)',
				},
				disabled: {
				  progressLabelColor: '#404040',
				  titleColor: '#3c3c3c',
				  background: '#D7D7D7',
				  imageOpacity: '1',
				  border: '1px solid rgb(130, 130, 130)',
				},
			},
      		colorOverride : {
				selectedBgColor : '#4589FF',
				checkedBg: '#4589FF',
				focusColor: '#4589FF',
				checkedFg: '#ffffff',
				fillBg: '#cccccc',
			},
			updateButton: {
				themeConfiguration: {
				  selectedBgColor: '#0B465A',
				  buttonBorder: '4px solid transparent',
				  borderOnFocus: '0',
				  borderBottom: '0',
				  paddingLeft: '0px',
				},
			},
			removeButton: {
				themeConfiguration: {
				  padding: '4px 0px 0px 0px',
				  selectedBgColor: 'rgb(69, 137, 255)',
				  margin: '0px 20px 0px 0px',
				  buttonBorder: '3px solid transparent',
				  borderOnFocus: '3px solid black',
				  borderBottom: '3px solid black',
				},
			},
			logoutButton: {
				themeConfiguration: {
				  selectedBgColor: 'rgb(69, 137, 255)',
				  border: '1px solid rgb(69, 137, 255)',
				  buttonBorder: '3px solid transparent',
				  borderOnFocus: '3px solid black',
				},
			},
			prevButtonTheme: {
				themeConfiguration: {
					bgColor: '#00465A',
					selectedBgColor: '#ffffff',
					border: '1px solid #00465A',
					borderRadius: '0px',
					padding: '7.5px 16px',
					buttonBorder: '1px solid #00465A',
					borderOnFocus: '2px solid #00465A',
					width: '83.335%',
		        },
				shape: 'rectangle',
				type: 'filled',
			},
			nextButtonTheme: {
				themeConfiguration: {
					bgColor: '#E9E9E9',
					selectedBgColor: '#00465A',
					border: '1px solid #00c39c',
					padding: '7.5px 16px',
					disabledPadding: '7.5px 16px',
					// margin: '3px 3px',
					focusMargin:'0px',
					borderRadius: '0px',
					buttonBorder: '1px solid transparent',
					borderOnFocus: '2px solid #00465A',
					bgOnFocus: '#00465A',
					width: '83.335%',
					hover:{
						selectedBgColor: '#D9E3E6',
						bgColor: '#00465A',
						border: '2px solid #00465A',
					},
					disabledTheme:{
						selectedBgColor: '#D7D7D7',
						bgColor: '#3c3c3c',
					},
					disabledPointer: 'not-allowed',
				},
				shape: 'rectangle',
				type: 'filled',
			},
			listButtonsTheme:{
				editButtonTheme:{
					themeConfiguration: {
						bgColor: 'rgb(0, 70, 90)',
						selectedBgColor: '#ffffff',
						buttonBorder: '3px solid transparent',
						borderOnFocus: '3px solid black',
						disabledPointer: 'not-allowed',
					},
				},
				deleteButtonTheme:{
					themeConfiguration: {
						// bgColor: 'rgb(255, 0, 0)',
						bgColor: 'rgb(0,70,90)',
						selectedBgColor: '#ffffff',
						buttonBorder: '3px solid transparent',
						borderOnFocus: '0',
						supportWCAGTheme: true,
						disabledPointer: 'not-allowed',
					},
				},
				closeButtonTheme: {
					themeConfiguration: {
						bgColor: 'rgb(255, 0, 0)',
						selectedBgColor: '#ffffff',
						buttonBorder: '3px solid transparent',
						borderOnFocus: '3px solid black',
						disabledPointer: 'not-allowed',
					},
				},
				addButtonTheme: {
					themeConfiguration: {
						bgColor: 'rgb(0, 70, 90)',
						selectedBgColor: '#ffffff',
						buttonBorder: '1px solid rgb(0, 70, 90)',
						borderOnFocus: '1px solid rgb(0, 70, 90)',
						borderRadius: '0px',
						disabledPointer: 'not-allowed',
					},
				},
				listItemAddButtonTheme: {
					themeConfiguration: {
						bgColor: '#ffffff',
					    selectedBgColor: 'rgb(0, 70, 90)',
						buttonBorder: '3px solid transparent',
						borderOnFocus: '0',
						disabledPointer: 'not-allowed',
					},
				},
				borderOnFocus: '3px solid black',
			},
			modalButton: {
				themeConfiguration: {
				  bgColor: '#ffffff',
				  selectedBgColor: 'rgb(69, 137, 255)',
				  padding: '5px 16px',
				  buttonBorder: '3px solid transparent',
				  borderOnFocus: '3px solid black',
				},
			},
			reactSelect: {
				showCustomIndicator: true,
				borderColor: '#ccc', // rgba(78,89,105,1) This changes normal border color
				selectedBgColor: '#0B465A',
				optionHoverColor: 'rgb(210, 228, 234)',
				focusColor: '#0B465A', // rgba(78,89,105,1) This changes border color on focus
				borderRadius: '0px',
				borderWidth: '0px',
				bottomBorderWidth: '1px',
				placeholder: {
				//   color: 'rgba(47, 56, 71, 1)',
				color: 'rgb(51, 51, 51)',
				},
				disabledPointer: 'not-allowed',
				indicatorSeparator: {
				  backgroundColor: '#ffff',
				  margin: '0',
				},
				indicatorContainer: {
				  color: '#0e1941',
				},
				selectLabel: {
				  color: '#2f3847',
				},
				selectWrapper: {
				  width: '97%',
				},
				customDropdownIndicator: {
				  color: '#0B465A',
				  fontSize: '22px',
				  width: '22px',
				},
				exactWidth: {
				  XXL: '472px',
				  M: '302px',
				},
			},
			primaryButton: {
				themeConfiguration: {
					bgColor: '#E9E9E9',
					selectedBgColor: '#00465A',
					border: '1px solid #00c39c',
					focusMargin:'0px',
					borderRadius: '0px',
					buttonBorder: '1px solid transparent',
					borderOnFocus: '2px solid #00465A',
					bgOnFocus: '#00465A',
					disabledPointer: 'not-allowed',
					hover:{
						selectedBgColor: '#D9E3E6',
						bgColor: '#00465A',
						border: '2px solid #00465A',
					},
					disabledTheme:{
						selectedBgColor: '#C9C9C9',
						bgColor: '#fff',
					},
				},
				shape: 'rectangle',
				type: 'filled',
			},
			text: {
				headingColor: 'rgb(51, 51, 51)',
				labelColor: 'rgb(51, 51, 51)',
				questionTextColor: '#5d5d5d',
				questionDescriptionColor: '#5d5d5d',
				sectionHeaderColor: 'rgb(0, 70, 90)',
			},
			colorLining: {
				quotereview: {
				  color: 'rgba(185, 198, 211, 1)',
				  width: '8px',
				},
				review: {
				  color: 'rgba(69, 188, 229, 1)',
				  width: '8px',
				},
				questions: {
				  color: 'rgba(69, 188, 229, 1)',
				  width: '8px',
				},
			},
		},
		checkboxTheme: {
			themeConfiguration: {
			  font:'15px Roboto-Medium',
			  border: '1px solid #454545',
			  bgColor: '#ffffff',
			  checkedBg: '#0B465A',
			  inputBorder: '3px solid transparent',
			  borderOnFocus: '2px solid #333',
			  outlineOffset: '2px',
			  borderRadiusOnFocus: '4px',
			  labelMargin: '8px 0',
			  top: '50%',
			  left: '10px',
			  disabledPointer: 'not-allowed',
			  disabledTheme: {
				bgColor: '#ccc',
				fontColor: '#ccc',
			  },
			},
		},
		components: {
			input: {
				floatingLabel: true,
				labelPosition: 'top',
				labelIsHTML: true,
				hideLabel: false,
				actualThreshold: 105,
				width: 'S',
				borderRadius: '0px',
				themeConfiguration: {
				  borderRadius: '0',
				  border: '0px solid #ccc',
				  errorBorderWidth: '0',
				  borderBottom: '1px solid #ccc',
				  focusColor: '#0B465A',
				  borderColor: '#4e5969',
				  disabledPointer: 'not-allowed',
				  labelText: {
					color: 'rgb(51, 51, 51)',
					fontSize: '15px',
					fontFamily: 'Roboto',
					lineHeight: '25px',
				  },
				  backgroundColor: '#ffffff',
				  labelWrapperStyles: {
					alignItems: 'baseline',
				  },
				  // placeHolder: {
				  //   fontSize: '16px',
				  //   color: 'rgba(185, 198, 211, 1)',
				  //   lineHeight: '24px',
				  // },
				  padding: '10px 12px 10px 12px',
				  inputBorder: '2px solid transparent',
				  shouldFocusWithin: false,
				  borderOnFocus: '#0e1941',
				  exactWidth: {
					// XXL: '480px',
					M: '305px',
					S: '235px',
					XS: '110px',
					XXS: '80px',
				  },
				},
			},
			'autocomplete-text': {
				floatingLabel: false,
				labelPosition: 'top',
				labelIsHTML: true,
				width: 'L',
				themeConfiguration: {
				  inputBorder: '3px solid transparent',
				  shouldFocusWithin: true,
				  borderOnFocus: '3px solid black',
				},
			  },
			radio: {
				convertToDropdown: true,
				card: {
				  shape: 'card',
				  orientation: 'rows',
				  overlap: true,
				  hasBorderTop: true,
				  hideTooltipWrapper: true,
				  themeConfiguration: {
					checkedFg: '#001e60',
					checkedBg: '#d9eeff',
					fontColor: '#6b7789',
					border: 'solid 4px #001e60',
					cardFontColor: '#00c39c',
					bgColor: '#ffffff',
					borderOnFocus: '4px solid #45BCE5',
				  },
				},
				defaultRadio: {
				  shape: 'classic',
				  orientation: 'columns',
				  themeConfiguration: {
					fillBg: '#cccccc',
					checkedFg: '#ffffff',
					checkedBg: '#001E60',
					fontColor: '#2f3847',
					borderOnFocus: '4px solid #45BCE5',
				  },
				},
				classic: {
					shape: 'classic',
					orientation: 'rows',
					showCustomInput: false,
				  	overlap: false,
					themeConfiguration: {
						selectedBgColor : '#0B465A',
						focusColor: '#0B465A',
						checkedBg: '#0B465A',
						checkedFg: '#ffffff',
						fillBg: '#cccccc',
						fontColor: 'rgb(69, 69, 69);',
						border: '0px solid #b9c6d3',
						borderOnFocus: '2px solid #00B0B9',
						outline: true,
						outlineOnFocus: '2px solid #333',
						outlineOffset: '2px',
					},
				},
				oval: {
				  shape: 'rectangle',
				  orientation: 'rows',
				  overlap: false,
				  overlapWithMargin: false,
				  themeConfiguration: {
					checkedFg: '#ffffff',
					checkedBg: '#0B465A',
					fontColor: '#0B465A',
					border: '1px solid #0B465A',
					whiteSpace: 'normal',
					borderOnFocus: '2px solid #00B0B9',
					customPadding: '13px',
					outline: true,
					outlineOnFocus: '2px solid #333',
					outlineOffset: '2px',
				  },
				},
				product_card: {
					themeConfiguration: {
						checkedFg: '#ffffff',
						checkedBg: '#0B465A',
						fontColor: '#0B465A',
						border: '1px solid #0B465A',
						whiteSpace: 'normal',
						borderOnFocus: '2px solid #0B465A',
					},
				},
				selectWrapper: {
				  color: '#2f3847',
				  borderOnFocus: '4px solid #45BCE5',
				},
			},
			selectOption: {
				floatingLabel: true,
				labelPosition: 'top',
				labelIsHTML: true,
				disableCapitalization: true,
				width: 'L',
			},
			singleSelect: {
				shapeOverride: {},
				width: 'L',
				floatingLabel: true,
				labelIsHTML: true,
				labelPosition: 'top',
				shape: 'rectangle',
				overlap: false,
				labelMargin: '0 !important',
				labelFont: '16px',
				removeMargin: true,
				customPadding: '17px 20px',
			},
			multiSelect: {
				width: 'L',
				floatingLabel: true,
				labelIsHTML: true,
				labelPosition: 'top',
				labelText: {
					themeConfiguration: {
						fontSize: '14px',
						fontFamily: 'Roboto-Bold',
					},
				},
			},
			datepicker: {
				width: 'L',
				floatingLabel: false,
				labelIsHTML: true,
				labelPosition: 'top',
				showPopper: true,
				guide: false,
				shouldFocusWithin: false,
				calendarIconColor: '#0B465A',
			},
			card : {
			  padding: 'clamp(24px, 5%, 80px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
			  showLeftBorder: true,
			  colorCode: '#dc3545',
			  themeConfiguration: {
				borderRadius: '0px',
				// width: '780px',
			  },
			},
			progressCard: {
				borderRadius: '0px',
			},
		},
		pageOverrides: {
			questions: {
				themeConfiguration: {
					labelText: {
						color: 'rgb(69, 69, 69)',
						fontFamily: 'Roboto',
						fontSize: '15px',
						lineHeight: '25px',
					},
				},
				card: {
					margin : {
						left: '50px',
					},
				},
				singleSelect: {
					labelText:{
						themeConfiguration:{
							color: 'rgb(69, 69, 69)',
							fontSize:'15px',
							fontFamily:'Roboto-Bold',
							lineHeight:'25px',
						},
					},
				},
			},
			quote: {
				input: {
					width: 'M',
					floatingLabel: true,
					labelIsHTML: false,
				},
				datepicker: {
					width: 'M',
					floatingLabel: true,
					labelIsHTML: false,
				},
				selectOption: {
					width: 'L',
					floatingLabel: true,
					labelIsHTML: false,
				},
				singleSelect: {
					width: 'M',
					floatingLabel: true,
					labelIsHTML: false,
				},
				multiSelect: {
					width: 'M',
					floatingLabel: true,
				},
			},
			quotereview: {
				button: {
					width: '150%',
					shape: 'rounded',
					type: 'outlined',
					color: 'blue',
				},
				input: {
					width: 'M',
					floatingLabel: true,
					labelIsHTML: false,
				},
			},
			signature: {
				is_next_btn_hidden: false,
				is_prev_btn_hidden: true,
				sectionHeaderWidth: '1200px',
				card: {
					width : '1200px',
				},
				maxWidth: '80%',

			},
			products: {
				maxWidth: '80%',
			},
			review: {
				singleLayoutLinearGradient: '#fff',
				headerColor: 'rgb(51, 51, 51)',
				breadCrumbFontSize: '28px',
				cardWidth: '780px',
				sectionHeaderWidth: '780px',
				headerFontSize: 60,
				headerLineHeight: 75,
				headerHeight: 75,
				subSectionHeaderColor: 'rgb(51, 51, 51)',
				fullWidthButton: {
					width: '280px',
					justify: 'left',
					margin: 'auto',
					containerWidth: '780px',
					containerMaxWidth: '80%',
				},
				headerTheme: {
					// 'background': '#fffff',
					'background': '#fffff no-repeat center top',
					backgroundImage: 'url(\'https://acqcdn.s3.amazonaws.com/brighthouse/breadcrumb/Group 6@1.5x.svg\')',
					backgroundSize: '861px 480px',
					position: 'absolute',
					top: '93px',
					width: '492px',
					right: '0px',
					backgroundRepeat: 'no-repeat',
					height: '381px',
				},
				imageMargin: '40px',
				imageHeight: '72px',
				imageWidth:  '52px',

			},
			completed: {
				sectionHeaderWidth: '780px',
				card: {
					width : '780px',
				},
				newBusinessTransaction: {
					cardWidth : '1200px',
					sectionHeaderWidth: '1200px',
				},
			},
			welcome: {
				maxWidth: '80%',
			},
			timeout: {
				maxWidth: '90%',
			},
		},
	},
	layout: {
		quote: 'quote',
		loader: 'single',
		questions: 'double',
		review: 'single',
		quoteResults: 'single',
		quotereview: 'single',
		plan: 'single',
		signature: 'single',
		payment: 'single',
		timeout: 'single',
		inactivesession: 'single',
		challenge: 'single',
		completed:'single',
		products: 'single',
		static: 'single',
		welcome: 'single',
	},
	features: {
		a11y: {
			hasModalBodyOutline: true,
		},
		login: {
			enable: false,
			loginURL: '/auth',
			logoutURL: '/auth/logout',
			refreshURL: '/auth/refresh_token',
			loginMethod: 'jwt',
			ssoConfig,
		},
		identityCheck: {
			enable: true,
		},
		sessionTimeout: {
			enable: true,
			triggerOnClose: false,
			timeout: {
				local: 55,
				development: 30,
				stg: 20,
				uat: 30,
				preprod: 30,
				production: 30,
			},
			alertTimeout: {
				local: 1,
				development: 1,
				stg: 2,
				uat: 5,
				preprod: 2,
				production: 2,
			},
		},
		liveChat: {
			enable: true,
			scriptSrc: 'https://api.salemove.com/salemove_integration.js',
		},
	},
};

export default brighthouse;