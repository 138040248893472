import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ data }) => {
	return (
		<a className="footer-links" href={ data.linkUrl } title={ data.linkText } rel="noopener noreferrer" target="_blank">
			{ data.linkText }
		</a>
	);
};

function FooterBottomLinks({ links }) {
	return (
		<div className="footer-links-container">
			{
				links.map(link => {
					return <Link href= ' ' key={ link.id } data={ link } />
				})
			}

		</div>
	);
}

export default FooterBottomLinks

FooterBottomLinks.propTypes = {
	links: PropTypes.arrayOf(PropTypes.object).isRequired,
};
