/* eslint-disable no-console */
import queryString from 'query-string';
import {
	getNextQuestionSet,
	getMockQuestionSet,
	setDestinationURL,
	triggerAuthAction,
} from './redux/actions';
import { getUid, getSessionId, getParams, getIPAddress } from './util';
import { SET_LOGIN_CONFIG, SET_ACTION_MESSAGE } from './redux/types';
import {
	mockAuthQuestions,
	mockInactiveSessionResponse,
	// mockNotFoundResponse,
} from './mockResponse';

import { setIPAddress } from './redux/actions/meta';
import LocalStorageServices from './util/localStorageServices';
import router from './util/router';
import SessionStorageServices from './util/sessionStorageServices';
import ssoAuth from './ssoAuth';

export default async function initApp(store, config, ssoAuthConfig) {
	const { getState, dispatch } = store;
	const { uid, meta } = getState();
	const urlUid = getUid(window.location.search);
	const sessionId = getSessionId(window.location.search);
	const event = getParams('event');
	const envelopId = getParams('envelop_id');
	const documentType = getParams('document_type');
	const identity = SessionStorageServices.getIdentity(getParams('uid'));

	/**
	 * @description Checks if login is enabled as per carrier's config.
	 */
	let loginRedirection = false;
	const { features } = config;
	const { login } = features;

	/**
	 * @description Sets login related configs in the reducer.
	 */
	if (login) {
		dispatch({
			type: SET_LOGIN_CONFIG,
			payload: login,
		});
	}

	const accessToken = LocalStorageServices.getAccessToken();

	// Fetch IP Address of client's system.
	getIPAddress(meta.ipAddress).then((ipAddress) => {
		dispatch(setIPAddress(ipAddress));
	});

	if (login.enable) {
		if (!accessToken) loginRedirection = true;
	}
	/**
	 * @description Stores the URL requested by the user.
	 */
	const { pathname, search } = window.location;

	const URL = pathname.concat(search);

	// Skip /questions API call if route contains document_type as url param
	if (documentType) {
		return;
	}
	// If identity is `invalid` redirect the user to access denied or not found page.
	if (identity === 'invalid' || pathname === '/inactivesession') {
		dispatch(getMockQuestionSet(mockInactiveSessionResponse));
		return;
	}

	/**
	 * @description Redirects the user to loginPage if loginRedirection is true,
	 * else gets the access_tokens by making the /auth API call (carrier specific).
	 */
	const { routes: carrierRoutes = [] } = config;
	const { IS_SSO_IDP_INITIATED_FLOW, ENABLE: ssoLoginEnable } = ssoAuthConfig;
	let isFromCarrierPanel = false;
	const queryParams = queryString.parse(search);
	console.debug('pathname');
	console.debug(pathname);
	const ssoAccessToken = LocalStorageServices.getSSOAccessToken();
	if(queryParams?.role==='home_office' && !ssoAccessToken) {
		LocalStorageServices.setLocalStorageValue('queryParams',search);
		isFromCarrierPanel = true;
		LocalStorageServices.setLocalStorageValue('isFromCarrierPanel',true);
	}

	if(LocalStorageServices.getLocalValue('isFromCarrierPanel')) isFromCarrierPanel = true;
	console.dir(ssoAuthConfig);
	console.log(pathname);

	/**
	 * In Idp flow, application launches from client's portal and we execute Auth request on /login
	 */
	const isIdpInitiatedScenario = IS_SSO_IDP_INITIATED_FLOW && (pathname.includes('/login')) && !isFromCarrierPanel;

	/**
	 * In Sp flow, we implement saml authentication on our acquire portal landing.(not on /login)
	 */
	const isSpInitiatedScenario = (!IS_SSO_IDP_INITIATED_FLOW || isFromCarrierPanel) && !pathname.includes('/login') && !ssoAccessToken;
	console.debug('isIdpInitiatedScenario --->',isIdpInitiatedScenario)
	console.debug('isSpInitiatedScenario --->',isSpInitiatedScenario)
	if (loginRedirection) {
		dispatch(setDestinationURL(URL));
		dispatch(getMockQuestionSet(mockAuthQuestions));
		return;
	// eslint-disable-next-line no-else-return
	} else if(ssoLoginEnable && (isIdpInitiatedScenario || isSpInitiatedScenario)) {
		ssoAuth(ssoAuthConfig, isFromCarrierPanel);
		console.log('SSO Initiation');
		return;
	}


	if (!accessToken && !ssoLoginEnable) {
		const validationSuccess = await dispatch(triggerAuthAction());
		if(!validationSuccess) {
			dispatch({
					type: SET_ACTION_MESSAGE,
					payload: {
						actionMessage: {
							type: 'error',
							message: 'Something went wrong!!',
						},
					},
				});
		}

	}
	if (sessionId) {
					dispatch(
						getNextQuestionSet('/questions', {
							session_id: sessionId,
						}),
					);
			return;
		}
		/**
		 * if URL does not contain URL, then it's a new application.
		 * Change it to /questions connect with APIs
		 */
		if (urlUid) {
			if (urlUid !== uid) {
				if (event && event.search('ttl_expired') < 0) {
					dispatch(
						getNextQuestionSet('/questions', {
							uid: urlUid,
							event,
							envelop_id: envelopId,
							next_page_flag: 1,
						}),
					);
				} else {
					dispatch(
						getNextQuestionSet('/questions', {
							uid: urlUid,
						}),
					);
				}
			}
		} else if (
			pathname === '/init' ||
			pathname === '/' ||
			(pathname.includes('/login'))
		) {
			dispatch(getNextQuestionSet('/questions', {}, {}));
		} else if (carrierRoutes.includes(pathname)) {
			const params = {
				isEmployee: ['/employee', '/employee/'].includes(pathname),
				route: pathname,
				...queryParams,
			};
			router(dispatch, null, false, null);
			dispatch(getNextQuestionSet('/questions', { ...params }, {}));
		} else {
			console.error(`Non defined route ${pathname}`)
		}
	}
