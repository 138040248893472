/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React , { useState, useLayoutEffect } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import ReactHtmlParser from 'react-html-parser';
import { flattenDeep } from 'lodash';
import { setScreenViewport } from './redux/actions/meta';
import { checkmodalReferenceIdString, checksQuotes, checksTextWithinQuotes } from './constants/regex';

/**
 * @description checks the innerHTML string for script tag.
 * @param str
 * @returns {string}
 */
const validateInnerHtml = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return str;
  try {
    if (str.match(/<script>/ig)) {
      throw new Error('Cannot insert script tags')
    }
    return str;
  } catch (e) {
    console.error(e);
    return '';
  }
}

/**
 * @description builds the nested array based on reflexive_index.
 * e.g. [q0, q0, [q1, q1, [q2, q2]], q0, q0]
 * @param list flattened list of questions
 * @param index
 * @returns {*}
 */



function rebuildQuestionList (list, index, doneIndex = -1) {
  return list.reduce((acc, q, i) => {
    if (q.reflexive_index === undefined) { q.reflexive_index = 0; }
    if (i >= doneIndex && !q.used && q.reflexive_index === index) {
      acc.push(q)
      q.used = true
    }
    if (!q.used && q.reflexive_index > index && doneIndex !== list.length) {
      acc.push(rebuildQuestionList(list, q.reflexive_index, i))
    }
    if(i > doneIndex && q.reflexive_index < index) {
      doneIndex = list.length
    }
    return acc;
  }, []);
}

function getRandomId() {
  return Math.random().toString(36).substring(7);
}

/**
 * Defines breakpoints based on the screen width
 * desktop - above 1024px
 * tablet - between 768px to 1024px
 * mobile - less than 768px
*/
function resizeHandler(dispatch, getState) {
  const { meta } = getState();
  const { screenViewport } = meta;
  if (window.innerWidth < 768 && screenViewport !== 'mobile') {
    dispatch(setScreenViewport('mobile'));
  } else if (window.innerWidth > 767 && window.innerWidth < 1025 && screenViewport !== 'tablet') {
    dispatch(setScreenViewport('tablet'));
  } else if (window.innerWidth > 1024 && screenViewport !== 'desktop') {
    dispatch(setScreenViewport('desktop'));
  }
}

const _resizeHandler = debounce(resizeHandler, 500);

function resizeListener({ dispatch, getState }) {
  _resizeHandler(dispatch, getState);
}

function singleButtonPage (id) {
  return id === 'quotereview' || id === 'review' || id === 'login';
}

function isReview (id) {
  return id === 'quotereview' || id === 'review';
}

function isCompletedPage(id) {
  return id === 'completed';
}

const buttonLabelMap = {
  login: 'Login',
  signature: 'Begin E-sign',
  quote: 'Get your quote',
  quotereview: 'Continue to application',
  review: 'Continue to application',
}

function isIe() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE');

  // If Internet Explorer, return version number
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return true;
  }
  return false;
}

function sanitize$uriefyQuestion (questionId) {
  try {
    return questionId.split(/_\$ureify_/)[1]
  } catch(e) {
    console.error('Failed to sanitize question id');
    return null;
  }
}

function isMatch(question, id) {
  if (question &&
    (question.question_id.indexOf(id) > -1 ||
    id.indexOf(question.question_id) > -1))
  {
    return true;
  }
  return false;
}

function lookUp(questionsArr, id) {
  return questionsArr.reduce((acc, q) => {
    if (acc) return acc;
    if (isMatch(q, id)) return q;
    if (q.questions) return lookUp(q.questions, id);
    return null;
  }, null);
}

function questionLookup(questionObj, id) {
  return lookUp([questionObj], id);
}

// Customhook for document resize
const useDocumentWidth = () => {
  const [width, setWidth] = useState()
  useLayoutEffect(() => {
    const updateWidth = () => {
      setWidth(document.documentElement.clientWidth)
    }
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  return width
}

// Utility to flatten out nested questions
const flattenQuestions = (questions, newList) => {
  if (questions.length) {
    questions.forEach((question) => {
      if(question.length){
        question.forEach(q => newList.push([q]))
      }else{
        newList.push({ ...question });
      }
      
      if (question.questions && question.submission_type !== 'all') {
        flattenQuestions(question.questions, newList);
      }
    });
  }
}

const insertReflexiveQuestions = (item) => {
  const reflexiveQ = [];
  const flattenedItems = flattenDeep(item)
  flattenQuestions(flattenedItems, reflexiveQ);
  return reflexiveQ;
}

// Utility for scrolling to top of the screen.
const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// Utility for scrolling to bottom of the screen.
const scrollBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
}

// Function for getting masked responses from questionsList
const getMaskedResponses = (questionsList, answersArray) => {
  const maskedQArray = [];
  const maskedQIds = answersArray.filter(q => q.question_id && q.hasMask).map(a => a.question_id);
	questionsList.forEach(q => {
		const hasMask = maskedQIds.includes(q.question_id)
		if (hasMask) {
      const response = get(q, 'original_response', q.response);
			maskedQArray.push({
				question_id: q.question_id,
				answer: response,
			})
		}
	});
	return maskedQArray;
}

const getUTCTimestamp = () => {
  return `${moment.utc().format('YYYY-MM-DD HH:mm:ss')} UTC`;
}

const getPopupDetails = (question, popupQuestions) => {
  let modalReferenceId
  let popupQuestion;
  const options = get(question, 'response_options', []);
  const questionText = get(question, 'question_text', '');
  // store modal_reference_id in variable
  if(question.display_type === 'checkbox') {
    const modalReferenceOption = options.filter(option => {
      const label = get(option, 'label', '');
      return (/modal_reference_id=/i).test(label);
    })[0];
    if(modalReferenceOption) {
      modalReferenceId = modalReferenceOption.label.match(checksTextWithinQuotes)[0].replace(checksQuotes, '');
    }	
  } else if(questionText.match(checkmodalReferenceIdString)) {
      modalReferenceId = questionText.match(checkmodalReferenceIdString)[0].split('=')[1].replace(checksQuotes, '');
    }
  // use modalReferenceId to find the question with same id in the popupQuestions array
  if(popupQuestions && popupQuestions.length > 0 ) {
    // eslint-disable-next-line prefer-destructuring
    popupQuestion = popupQuestions.filter(ques => {
      return ques.question_id === modalReferenceId
    })[0];
  }
  // render the Modal with popupQuestions data
  if(popupQuestion) {
    return{
      show:true,
      question: popupQuestion,
    };
  }
  return { show: false, question: '' };
}

const stripHtml = (html) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const parseHtml = (htmlText, clickHandler, isAsterisk = false) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {  
      clickHandler(e);
    }
  }
  return ReactHtmlParser(htmlText, {
    transform: (node) => {
      if(node.name === 'a' && node.attribs && node.attribs.modal_reference_id) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (<a onClick={ clickHandler } className='anchor' onKeyDown={ (e) => handleKeyDown(e) } role='button' tabIndex='0'>{node.children[0].data}</a>);
      }
      if(isAsterisk)
        return stripHtml(htmlText);
    },
  })
}

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 0) {
          console.error('maximum retries exceeded');
          reject(error);
          return;
        }
        setTimeout(() => {
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const toDashCase = (text) => {
	if (typeof text === 'string') {
		return text.trim().replace(/\s+/g, '-').toLowerCase();
	}
	return text;	
};

const getFormatDate = (date = new Date(), formatValue = 'YYYY-MM-DD HH:mm:ss') => {
  let _date = date;
  if (!_date) return false;

  if (moment(date, formatValue, true).isValid())
    _date = moment(date).format(formatValue)

  return _date
}

export {
  validateInnerHtml,
  rebuildQuestionList,
  getRandomId,
  resizeListener,
  isReview,
  singleButtonPage,
  buttonLabelMap,
  isIe,
  sanitize$uriefyQuestion,
  questionLookup,
  insertReflexiveQuestions,
  scrollTop,
  scrollBottom,
  getMaskedResponses,
  getPopupDetails,
  parseHtml,
  getUTCTimestamp,
  isCompletedPage,
  useDocumentWidth,
  retry,
  toDashCase,
  stripHtml,
  getFormatDate,
}
