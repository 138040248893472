import React from 'react';
import get from 'lodash/get';
import { Modal, Button } from 'crux';
import { connect } from 'react-redux';
import styled,{ withTheme } from 'styled-components';
import { setPopupDetails } from '../../redux/actions/questions';
import { MODALTYPES } from './index'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FooterNote = styled.div`
  text-align: left;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 20px;
`;


const TooltipModal = ({ popupDetails, updatePopupDetails, theme }) => {
  const hasbodyOutline = get(theme,'config.features.a11y.hasModalBodyOutline', false);
  const { question, label = '', showModalClose = true, handleClick = () => {}, type = MODALTYPES.default } = popupDetails;
  const {
    question_text: heading,
    question_description: body,
    footer_note: footerNote,
  } = question;
  const handleClose = () => {
    updatePopupDetails({
      ...popupDetails,
      show: false,
      force: false,
      type: '',
      label: '',
      question: {},
      showModalClose: true,
      handleClick: () => {},
    });
  }
  const onClickHandler = () => {
    if (type === MODALTYPES.retry || type === MODALTYPES.alertTimeout) {
      handleClick();
    }
    handleClose();
  }
  return (
    <Modal
      modalHeader={ heading }
      modalBody={ body }
      modalFooter={
        <>
          <hr />
          <ButtonWrapper
            onClick={ () => {
              updatePopupDetails({
                ...popupDetails,
                show: false,
              });
            } }
          >
            <Button shape='rounded' label= { label || 'Close' }  width='300px' handleButtonClick={ onClickHandler } tabIndex={ 0 }/>
          </ButtonWrapper>
          { footerNote && <FooterNote dangerouslySetInnerHTML={ { __html: footerNote } } /> }
        </>
      }
      showModalClose={ showModalClose  }
      handleClose={ handleClose }
      overlayBgColor='#013b5b'
      hasbodyOutline={ hasbodyOutline }
    />
  );
};

const mapStateToProps = (state) => {
  const popupDetails = get(state, 'questions.popupDetails', { show: false, question: '' });
  return {
    popupDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePopupDetails: (payload) => {
			dispatch(setPopupDetails(payload));
		},
  }
}
export default withTheme(connect(mapStateToProps, mapDispatchToProps)(TooltipModal));
