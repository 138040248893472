import React from 'react';
import styled from 'styled-components';

const ExpiryWrapper = styled.div`
  max-width: 944px;
  margin: 0 auto;
  width: 90%;
`;

const FooterExpiry = ({ policyExpiry }) => {
  return <ExpiryWrapper>{policyExpiry}</ExpiryWrapper>;
};

export default FooterExpiry;
