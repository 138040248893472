import get from 'lodash/get';
import { push } from 'connected-react-router';
import { getCurrentBreadcrumb } from '../redux/reducers/helper';
import { PAGE_ACTIVITY_LOGGER } from '../redux/types';

export const pageRouter = (dispatch,route) => {
	dispatch(push(`/${route}`));
}

export default function router(dispatch, uid, cjEventId, response) {
	const currentStatus = get(
		response,
		'data.response.other_params.current_status',
		'',
	);
	const breadcrumbs = get(response, 'data.response.breadcrumbs', []);
  const currentBreadcrumb = getCurrentBreadcrumb(breadcrumbs);
  const pageActivityLogger =['completed','signature']
  
  const redirectUrl = get(response, 'data.response.other_params.next_button_redirect_url', false);
  if(pageActivityLogger.indexOf(currentStatus)>-1){
    dispatch({
      type:PAGE_ACTIVITY_LOGGER,
      params:{ currentStatus },
    })
	if(redirectUrl) {
		window.location.replace(redirectUrl);
	}
  }

  // Processing screen redirection
  if (currentStatus === 'processing') {
	if(redirectUrl) {
		setTimeout(() => {
			window.location.replace(redirectUrl);
		}, 5000);
	}
  }

	if (currentStatus === 'questions') {
		if(cjEventId) {
			dispatch(push(`/questions/${currentBreadcrumb.id}?uid=${uid}&cjevent=${cjEventId}`));
		} else {
			dispatch(push(`/questions/${currentBreadcrumb.id}?uid=${uid}`));
		}
	} else if (uid) {
		if(cjEventId) {
			dispatch(push(`/${currentStatus}?uid=${uid}&cjevent=${cjEventId}`));
		} else {
			dispatch(push(`/${currentStatus}?uid=${uid}`));
		}
	} else {
		dispatch(push(`/${currentStatus}`));
	}
}
