import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/configureStore';
import { retry } from './utils';
import Loader from './cruxComponents/Loader';
import ScrollToTop from './ScrollToTop';
import GetNewUID from './Pages/GetNewUID';
import UnSubscribed from './Pages/Unsubscribed';

// Add retry to lazy components in case of loading/chunk failures -
// Reference - https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/

// const GetNewUID = lazy(() => import('./Pages/GetNewUID'));
const NewQuestions = lazy(() => retry(() => import('./Pages/Questions/NewQuestions')));
const QuoteDataCollectionPage = lazy(() => retry(() => import('./Pages/QuoteDataCollectionPage')));
const PageNotFound = lazy(() => retry(() => import('./Pages/PageNotFound')));
const PDFDownloadLink = lazy(() => retry(() => import('./Pages/PDFDownloadLink')));
const SSORedirection = lazy(() => retry(() => import('./Pages/SSORedirection')));

const AppRouter = () => {
	return (
		<>
			<ConnectedRouter history={ history } noInitialPop>
				<ScrollToTop>
					<Suspense fallback={ <><GetNewUID /></> }>
						<Switch>
							<Route
								path={ [
									'/quote',
									'/plan',
									'/timeout',
									'/inactivesession',
									'/quotereview',
									'/signature',
									'/questions/:id',
									'/review',
									'/payment',
									'/challenge',
									'/riders',
									'/completed',
									'/knockout',
									'/products',
									'/launch',
									'/referral',
									'/static',
									'/welcome',
									'/health',
									'/EHR',
								] }
								component={ NewQuestions }
							/>
							<Route
								path={ [
									'/unsubscribed',
								] }
								component={ UnSubscribed }
							/>
							<Route
								path={ [
									'/processing',
									'/interview',
								] }
								component={ Loader }
							/>
							<Route
								path={ [
									'/login',
								] }
								component={ QuoteDataCollectionPage }
							/>
							<Route
								path={ [
									'/view-document',
								] }
								component={ PDFDownloadLink }
							/>
							<Route
								exact
								path={	[
									'/',
									'/init',
								] }
								component={ GetNewUID }
							/>
							<Route
								exact
								path={	[
									'/ssodone',
									'/authError',
								] }
								component={ SSORedirection }
							/>
							<Route
								exact
								path="*"
								component={ PageNotFound }
							/>
						</Switch>
					</Suspense>
				</ScrollToTop>
			</ConnectedRouter>
		</>
	);
};

export default AppRouter;
