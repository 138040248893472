import React from 'react';
import styled from 'styled-components';

const TelWrapper = styled.div`
  max-width: 944px;
  text-align: right;
  margin: 0 auto;
  width: 90%;
  & > .tel {
    text-align: right;
    font-weight: 900;
    font-size: 18px;
  }
`;
const FooterTelephone = ({ custCareTel }) => {
  return (
    <TelWrapper>
      <a className="tel" href="tel:844-596-1379">
        {custCareTel}
      </a>
    </TelWrapper>
  );
};

export default FooterTelephone;
