import React from 'react';
import { withTheme } from 'styled-components';
import Content from '../../layout/content';
import Loader from '../../cruxComponents/Loader';

function GetNewUID(props) {
  const {
		theme: {
			config: {
				theme: {
					global: {
						background,
					},
				},
			},
		},
	} = props;
  return (
    <Content
			leftData={ [] }
			loading
			reload={ false }
      		layout=''
			background={ background }
		>
      <Loader />
    </Content>
  )
}

export default withTheme(GetNewUID);
