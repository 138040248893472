import styled from 'styled-components';

const getBgColor = (props) => {
	const { screenViewport, layout, background, loading } = props;
	if (loading) {
		return '#fff';
	}

	if (screenViewport === 'mobile') {
		return '#fff';
	}

	if ((screenViewport === 'mobile') && layout === 'single') {
		return background;
	}
	if ((screenViewport === 'mobile') && layout === 'single') {
		return background;
	}
	
	if (layout === 'auto') {
		return '#fff';
	}

	if (layout === 'double') {
		return '#fff';
	}
	
	return 'transparent';
};

const getRestStyles = (props) => {
	const { isIe, screenViewport } = props;
	if (isIe) {
		return `
			position: relative;
			margin-bottom: -100px;
		`;
	}
	if (screenViewport === 'mobile') {
		return `
			position: relative;
			height: 75px;
		`;
	}
	return `
		position: fixed;
		height: 100px;
	`;
}

const HeaderWrapper = styled.div`
	background: ${(props) => getBgColor(props)};
	display: flex;
	width: 100%;
	opacity: ${({ fade }) => (fade ? '0' : '1')};
	transition: opacity 0.3s ease-in-out;
	z-index: ${({ fade }) => (fade ? '99' : '100')};
	border-bottom: 7px solid #e9e9e9;
	${(props) => getRestStyles(props)};
	top: 0;
	@media (max-width: 767px) {
		border-bottom: 0;
	} 
`;

const Logo = styled.div`
	margin-top: ${({ screenViewport }) => screenViewport === 'mobile' ? '20px' : '28px'};
	margin-left: ${({ screenViewport }) => screenViewport === 'mobile' ? '16px' : '40px'};
	@media (forced-colors: active) {
		img {
		forced-color-adjust: none;
		}
	}
	img {
		max-width: ${({ screenViewport }) => screenViewport === 'mobile' ? '190px' : '234px'};
		height: ${({ screenViewport }) => screenViewport === 'mobile' ? '32px' : '40px'};
	}
	flex-grow: 1;
`;

const Links = styled.div`
	flex-grow: 3;
	background: '#fff'
`;

const ButtonWrapper = styled.div`
	position: fixed;
	right: 20px;
	top: 20px;
	display: flex;
	align-items: center;
	margin: 8px 15px;
	z-index: 110;
	& > button {
		font-weight: bold;
		background-color: white;
	}
`;

export { HeaderWrapper, Logo, Links, ButtonWrapper };
