
const ssoConfig = {
    local: {
        ENABLE: false,
        FRONTEND_BASE_URL: 'http://localhost:3000',
        LIFETIME_AUTH_URL: 'http://localhost:5000',
        IS_SSO_IDP_INITIATED_FLOW: false,
        SSO_USER_TYPE:'saml-salesforce',
        SSO_REDIRECT_URL:'http://localhost:3000/ssodone',
        CARRIER_PANEL_USER_TYPE: 'saml-salesforce',
    },
    development: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-dev.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'saml-test',
        SSO_REDIRECT_URL:'https://brighthouse-dev.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure',
    },
    stg: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-stg.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-stg.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure',
    },
    stg2: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-stg.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-stg.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure',
    },
    uat: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-uat.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-uat.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure',
    },
    preprod: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-preprod.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-preprod.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure-preprod',
    },
    production: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-prod.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-prod.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure-prod',
    },
    prod: {
        ENABLE: false,
        LIFETIME_AUTH_URL: 'https://brighthouse-prod.sureify.com',
        IS_SSO_IDP_INITIATED_FLOW: true,
        SSO_USER_TYPE:'bh-opentext',
        SSO_REDIRECT_URL:'https://brighthouse-prod.sureify.com/ssodone',
        CARRIER_PANEL_USER_TYPE: 'bh-azure-prod',
    },
  }
const ssoConfigEnv = ssoConfig[process.env.REACT_APP_NODE_ENV];

export default ssoConfigEnv;
