/* eslint-disable import/no-extraneous-dependencies,react/no-danger */
import React from 'react';
import get from 'lodash/get';
import { Button, Tooltip } from 'crux';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { at } from 'lodash';
import './ReviewLabel.css';
import { ErrorTypes } from '../../../util';
import { getNextQuestionSet } from '../../../redux/actions';
import { validateInnerHtml } from '../../../utils';
import { getCurrentQuestionSet } from '../../../redux/selector/questions';
import { getCSSVariables } from '../../../cruxComponents/utils';
import { handleActionMessage, toggleSignatureContinueButton } from '../../../redux/actions/questions';
import { API_IN_PROGRESS } from '../../../redux/types';
import { axiosInstance } from '../../../util/axios';

const ButtonWrapper = styled.div`
  margin-bottom: 30px;
  padding-left: 6px;
  & > button {
    padding-right: 0;
  }
`;

const TooltipIcon = styled.div`
  margin: 0px 10px;
  text-align: left;
`
const CollapseHeader = styled.div`
display: flex;
justify-content: space-between;
width: inherit;
align-items: baseline;
font-size: 20px;
& div.card {
border: none;
}
& div i{
  color:#00465a
}
`
const QuestionButton = styled.button`
  display: inline;
  background: transparent;
  border: 0;
  padding: 9px;
  color: #00465a;
  text-align: inherit;
  font-weight: bold;
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
`;

const ReviewQuestionStyles =  styled.div`
  ${({ questionProps: { grid_display: gridDisplay, grid_positional_area: gridArea, text_align: textAlign } = {} }) => gridDisplay && `
      display: grid !important;
      grid-template-columns: 1fr 5fr 1fr;
      grid-template-areas: "left-space center-space right-space";
      > div {
        grid-area: ${gridArea};
        text-align: ${textAlign} !important;
      }
  `}

  ${({ isQuestionId }) => isQuestionId &&
    `
      > div {
        margin-left: auto;
        margin-right: auto;
      }
      @media only screen and (min-width: 1280px) {
        > div {
          font: 18px Roboto !important;
        }
      }
    `
  }
`
const ToggleIcon = styled.div`
  ${({ activeEl }) => activeEl && `
  transform : rotate(180deg)`}
`;


const ReviewAnswerStyles = styled.div`

  align-self: center;
  ${({ displayType, questionType, qTextLen, qTextLbreakSplit }) => displayType === 'dropdown' && questionType === 'singleselection' && qTextLen > 2 &&
  `
    ${qTextLbreakSplit[qTextLen - 1] && `margin-bottom: ${25 * (Math.round(qTextLbreakSplit[qTextLen - 1].length / 40))}px`};
    align-self: flex-end;
  `
  }
  ${({ activeSubsectionId, isGroupQId, id, displayType }) => ((activeSubsectionId === 'completed' && isGroupQId) || id?.match(/button/i) && !displayType === 'upload') &&
    `margin-left: auto;
     margin-right: auto;  `
  }
  a {
    font: 500 17px Roboto-Medium;
    color: #fff;
    background: rgb(0, 70, 90);
    text-align: center;
    display: inline-block;
    padding: 14px;
    width: auto;
    height: 50px;
    margin-top: 10px;
  }
  @media (max-width: 567px) {
    a{
      padding: 7px 14px;
    }
  }
  .agentCTAContainer,
  .customerCTAContainer {
    margin: 50px -35px;
    width: 800px;
  }
  .agentCTABody,
  .customerCTABody {
    font-family: "Roboto",sans-serif;
    text-align: center;
    font-weight: 700;
    padding: 20px 60px;
  }
  .agentCTABody img,
  .customerCTABody img {
    display: block;
    margin: 1em auto 2em;
  }
  .agentCTABody h3,
  .customerCTABody h3 {
    color: #333;
    font-weight: inherit;
  }presentationType
  .agentCTABody p,
  .customerCTABody p {
    margin-left: auto;
    font-weight: 400;
  }
  .agentCTABody a,
  .customerCTABody a {
    display: block;
    color: #00465a;
    font-size: 17px;
    font-weight: inherit;
    text-decoration: underline 0.15em rgba(255, 255, 255, 0);
    transition: text-decoration-color 300ms;
    background: inherit;
    margin: 0 auto;
  }
  .agentCTABody a:after,
  .customerCTABody a:after {
    content: '>';
  }
  .agentCTABody a:hover,
  .customerCTABody a:hover {
    text-decoration-color: rgba(0, 70, 90, 1);
  }
  .agentDisclosure,
  .customerDisclosure {
    color: #333;
    font-family: "Roboto",serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 25px;
    text-align: left;
  }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .agentCTAContainer,
    .customerCTAContainer {
	    margin: 50px -22px 0;
    }
    .agentCTABody,
    .customerCTABody {
      padding: 20px 0px;
    }
  }
  @media only screen and (max-width: 1125px) {
    .agentCTAContainer,
    .customerCTAContainer {
      width: 130%
    }
  }
  display: flex;
 ${({ presentationType, activeEl, id }) => presentationType ==='Q&A' && `
 display :${activeEl === id? 'block !important' : 'none !important'};
 > p{
   margin:12px 0;
 }
 & {
  margin-left: 0;
 }
 `}
  ${( { activeEl } )=> activeEl && `
  // width: 50em;
  font: 19px / 35px Roboto !important;
  // margin-left: auto !important;
  padding-left: 10px;
  `}

  @media (max-width: 768px) {
    align-self: flex-start;
  }
  ${({ activeSubsectionId }) => ((activeSubsectionId === 'completed')) &&
    `@media (min-width: 320px) and (max-width: 767px) {
      & {
        margin: 0% 8% 0% 8% !important;
      }
    }`
  }
`;

const RevieLabelContainer = styled.div`
  ${({ isEmptyReviewQ, pageId, questionText, labelOrientation }) => isEmptyReviewQ && pageId === 'review' && `
     justify-content: ${!labelOrientation.match(/left/i) && 'flex-end'};
     ${questionText.trim() !== '' && `
        > div{
          width: 45%;
        }
     `}
  `}
  ${({ pageId }) => pageId!== 'review' && `
    > div {
      text-align: left !important;
    }
  `}
  ${({ pageId }) => pageId!== 'review' && `
    > div {
      text-align: left !important;
    }
  `}
`

class ReviewLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentElement: '',
    }
  }

  componentDidMount() {
    const {
      questionObj: { question_id: questionId, response },
      toggleSignatureContinue,
      activeSubsectionId = '',
    } = this.props;

    const isContinueBtnDisabled = get(this.props, 'questionObj.is_next_btn_disabled', false)
    const _sectionIndex = get(this.props, 'sectionIndex', 0)


    if (response) {
      const { handleLabelChange } = this.props;
      handleLabelChange(questionId, response, ErrorTypes.noError);
    }

    if(isContinueBtnDisabled && activeSubsectionId !== 'review')
      toggleSignatureContinue(!isContinueBtnDisabled, _sectionIndex, activeSubsectionId)

    /** MS Clarity - review ans Masking */
    const textElementContainer = document.querySelectorAll(`.display_review_answer`);
    const reviewAnsAry = [...textElementContainer];
    reviewAnsAry.forEach(div => {
      div?.setAttribute('data-clarity-mask', 'True');
    })
  }

  render() {
    const {
      questionObj: {
        question_id: questionId,
        question_text: questionText,
        display_type: displayType,
        label: questionLabel,
        question_type: questionType,
        is_edit_icon_visible: isEditable,
        presentation_type: presentationType,
        orientation,
        response,
        validations,
        properties,
        solidLine,
      },
      uid,
      theme,
      isReview,
      makeAPICall,
      toggleSignatureContinue,
      tooltipProps,
      screenViewport,
      activeSubsectionId,
      groupQId,
      pageId,
      _updateLoading,
      _handleActionMessage,
      pageSeqNumber,
      sectionIndex,
    } = this.props;
    let _orientation = orientation;

    /** Display Review Question Properties - Completed Route Grid style Configurations */
    const questionProps = get(this.props, 'questionObj.properties', {})

    if (questionId?.match('Signature') || (screenViewport === 'mobile')) {
      _orientation = 'columns';
    }
    if (isReview && questionType === 'group') {
      _orientation = 'rows';
    }
    let responseText = response;
    if (response) {
      const { label } = response;
      if (label) responseText = label;
    }

    let metric = {};
    if(validations && validations.metric){
       metric = validations.metric;
    }

    let parentGroupId = '';

    if(properties && properties.parent_group_id) {
       parentGroupId = properties.parent_group_id.split('_').join(' ');
    }

    if (metric && metric.value) {
      const metricValue = metric.value;
      if(metric.display_position && metric.display_position === 'postfix'){
        responseText = `${responseText  } ${  metricValue}`;
      }else if(metric.display_position && metric.display_position === 'prefix')
        responseText = `${metricValue  } ${  responseText}`;
    }

    const displayUpdateButton = questionType === 'group' && isEditable;
    const handleUpdate = () => {
      const params = {
        uid,
        q_id: questionId,
      };
      makeAPICall(params);
    };
    const toggleQA = () => {
      this.setState((prevState) => ({
        currentElement: `${
          prevState.currentElement === questionId
        ? '': questionId}`,
      }))
    };

    const makeQuestionsCall = async (queryParams) => {
      const _queryParams = {
        ...queryParams,
        leave_behind_form_clicked: 1,
        question_id: questionId,
      }
      axiosInstance
        .post('/questions', _queryParams)
        .then((_response) => {
          const currentStatus = get(
            _response,
            'data.response.other_params.current_status',
            '',
          ).toUpperCase();

          if (currentStatus){
            // _updateQuestionsResponse(currentStatus, _response, { sectionIndex, isReflexive: true });
            const isLeaveBehindFormClicked = true
            if(activeSubsectionId !== 'review')
              toggleSignatureContinue(true, sectionIndex, activeSubsectionId, isLeaveBehindFormClicked);
          }
          else
           _handleActionMessage(_response);

          _updateLoading(false);
        })
        .catch((error) => {
          console.error(error);
          _updateLoading(false);
          _handleActionMessage(error);
        });
    };

    const getQuestionTextClass = () => {
      let className = `display_review_question ${_orientation} `;
      if (questionType === 'group') className += 'group-question ';
      else if (questionType === 'text' && presentationType !== 'review') className += '';
      else className += 'display_normal';
      if(questionType === 'label' &&  !response){
        className += ' display_label';
      }
      return className;
    };

    const updateButtonTheme = get(theme, 'config.theme.global.updateButton.themeConfiguration', {});
    const questionLabelText = get(questionLabel, 'label_text', '')
    const questionLabelAlignment = get(questionLabel, 'label_position', '')
    const textElement= (questionType === 'group' ? '.reviewlabel-group' : '.reviewlabel-text');
    const desElement='.reviewlabel-des';
    const cssVariables =  getCSSVariables(theme);
    const updateButtonElement='.button-update';
    const textFont = at(cssVariables, [`pageOverride.font${textElement}`, `global.font${textElement}`]);
    const desFont = at(cssVariables, [`pageOverride.font${desElement}`, `global.font${desElement}`]);
    const updateButtonFont = at(cssVariables,[`pageOverride.font${updateButtonElement}`, `global.font${updateButtonElement}`] )
    updateButtonTheme.font=updateButtonFont[0] || updateButtonFont[1]

    const reviewClass = (questionType === 'group' && solidLine) ? 'review-solid-line-2' : '';
    const reviewAnswerTextLength =  responseText.toString().indexOf('</') - (responseText.toString().lastIndexOf('\'>') + 2);
    const questionIds = ['thankyou'];
    const qTextLbreakSplit = questionText?.split('<br/>')
    const qTextLen = questionText?.split('<br/>')?.length


    /**
     * - Completed Route - cardWidth/sectionHeaderWidth custom width config
    */
    const pageTypes = ['FinalDecisionRefer', 'WelcomeBreadcrumbNY', 'BH_NYCustThankyouScreen'];
    const qIdRegex = new RegExp(`(${questionIds.join('|')})`, 'i');
    const pageTypeRegex = new RegExp(`(${pageTypes.join('|')})`, 'i');
    const { currentElement } = this.state;
    const isContinueBtnDisabled = get(this.props, 'questionObj.is_next_btn_disabled', false)
    const isReflexive = get(this.props, 'questionObj.child_questions', false)
    const questionResponse = get(this.props, 'questionObj.response', '')
    const queryParams = {
      page_sequence_number: pageSeqNumber,
      uid,
    }
    const reviewAnswerStylesProps = {
      id: questionId,
      presentationType,
      displayType,
      questionType,
      qTextLbreakSplit,
      qTextLen,
      activeSubsectionId,
      onClick: () => {
        if(isContinueBtnDisabled || (isReflexive && questionResponse?.match(/view-document/i))){
          _updateLoading(true);
            setTimeout(() => {
              makeQuestionsCall(queryParams);
            }, 5000);
        }
      },
    }

    return (
      <div key={ questionId }>
        <div className={ reviewClass } />
        <RevieLabelContainer className={ `display_review_flex_center ${_orientation}` } labelOrientation={ questionLabelAlignment } isEmptyReviewQ = { questionText.trim() === '' } pageId={ pageId } questionText={ questionText }>
          {(questionText.trim() !== '' || tooltipProps.hasTooltip || (questionLabelText.trim() !== '')) && (
            <ReviewQuestionStyles
              className={ getQuestionTextClass() }
              isQuestionId={ qIdRegex.test(questionId) }
              questionText={ questionText }
              questionProps = { questionProps }
            >
              {(questionText.trim() !== '' || questionLabel?.label_text.trim() !== '') &&
                (presentationType !== 'Q&A' ? (
                  <div
                    style={ {
                      font: textFont[0] || textFont[1],
                      textAlign: 'justify',
                    } }
                    htmlFor={ questionId }
                    dangerouslySetInnerHTML={ {
                      __html: validateInnerHtml(questionText || questionLabel?.label_text),
                    } }
                  />
                ) : (
                  <>
                    <CollapseHeader onClick ={ toggleQA }>
                      <div className="card">
                        <QuestionButton
                          type="button"
                        >
                          {questionText}
                        </QuestionButton>
                      </div>
                      <ToggleIcon activeEl={ currentElement }><i className="fa fa-chevron-down pull-right"/></ToggleIcon>
                    </CollapseHeader>
                  </>
                ))}
              {tooltipProps.hasTooltip && (
                <TooltipIcon>
                  <Tooltip
                    ariaLabel={ `${questionText} tooltip` }
                    name="tool-tip"
                    { ...tooltipProps }
                  />
                </TooltipIcon>
              )}
            </ReviewQuestionStyles>
          )}
          {displayUpdateButton ? (
            <ButtonWrapper>
              <Button
                label="Update"
                type="text"
                handleButtonClick={ handleUpdate }
                ariaLabel={ `Update ${questionText || parentGroupId}` }
                themeConfiguration={ updateButtonTheme }
              />
            </ButtonWrapper>
          ) : (
            <ReviewAnswerStyles
              className={ `display_review_answer ${_orientation}` }
              style={ { font: desFont[0] || desFont[1] } }
              textLength={ reviewAnswerTextLength }
              isGroupQId={ pageTypeRegex.test(groupQId) }
              dangerouslySetInnerHTML={ {
                __html: validateInnerHtml(responseText),
              } }
              activeEl={ currentElement }
              { ...reviewAnswerStylesProps }
            />
          )}
        </RevieLabelContainer>
        {presentationType === 'Q&A' && <div className="faq-line-break" />}
        <div className="review-dotted-line-break" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { review } = getCurrentQuestionSet(state);
  const pageSeqNumber = get(state, 'questions.currentPageSeqNumber', -1);
  return {
    isReview: review && review.isReview,
    uid: state.uid,
    screenViewport: state.meta.screenViewport,
    activeSubsectionId: state?.questions?.activeSubsectionId,
    groupQId: state.questions?.pageType,
    pageSeqNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (params) => {
      dispatch(getNextQuestionSet('/questions', params));
    },
    toggleSignatureContinue: (buttonState, sectionIndex, activeSubsectionId, isLeaveBehindFormClicked) => {
      dispatch(toggleSignatureContinueButton(buttonState, sectionIndex, activeSubsectionId, isLeaveBehindFormClicked))
    },
    _updateLoading: (data) => { dispatch({ type: API_IN_PROGRESS, payload: { isLoading: data } })},
    // _updateQuestionsResponse: (currentStatus, response, params) => {
    //   dispatch({
    //       type: `GET_${currentStatus}_SUCCESS`,
    //       payload: get(response, 'data.response', null),
    //       params,
    //   });
    // },
    _handleActionMessage: (response) => {
      dispatch(handleActionMessage(response));
    },
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ReviewLabel));