/* eslint-disable max-len */
/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import findLast from 'lodash/findLast';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { SET_ACTION_MESSAGE } from '../redux/types';
import { getParams, getIPAddress } from '../util';
import host from '../util/host';
import router from '../util/router';
import { setIPAddress } from '../redux/actions/meta';
import { setPopupDetails } from '../redux/actions/questions';
import { mockAlertPopup } from '../mockResponse';
import { MODALTYPES } from '../cruxComponents/utils';

export const currentSocket = null;
class HealthCheck extends React.PureComponent {
	constructor(props) {
		super(props);
		this.healthCheckInitiated = false;
		this.uid = '';
		this.excludePages = ['timeout', 'authcode'];
		this._connectSocket = this._connectSocket.bind(this);
	}

	componentDidMount() {
		const { uid, sessionId, sessionType } = this._getIds();

		if (uid || sessionId) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Mount.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
		if (this.healthCheckInitiated) {
			this._emitUpdatePageVisits();
		}
	}

	componentDidUpdate() {
		const { uid, sessionId, sessionType } = this._getIds();

		if (!this.healthCheckInitiated && (uid || sessionId)) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Update.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
	}


	_getIds = () => {
		const { location } = window;
		let uid = '';
		let sessionId = '';
		let sessionType = '';
		if (location.search.match(/uid/)) {
			uid = getParams('uid');
			sessionType = 'uid';
		}
		if (location.search.match(/session_id/)) {
			sessionId = getParams('session_id');
			sessionType = 'session_id';
		}
		return {
			uid,
			sessionId,
			sessionType,
		};
	}

	handleAlertTimeoutContinue = () =>{
		console.log(
			'HEALTH CHECK:::  PONG_ALERT_TIMER_RESET',
			JSON.stringify(this.uid),
		);
		this.socket.emit(
			'PONG_ALERT_TIMER_RESET',
			JSON.stringify({ uid: this.uid }),
		);
	}

	_emitUpdatePageVisits() {
		this.socket.emit(
			'UPDATE_PAGE_VISITS',
			JSON.stringify({
				uid: this.uid,
				last_visited_page: findLast(window.initialTagManager, (e) => {
					return e;
				}),
				tag_manager: uniqBy(window.initialTagManager, (e) => {
					return e.page_id;
				}),
			}),
		);
	}

	_shouldStartSocketConnection() {
		const currentPath = window.location.pathname.replace('/', '');
		if (currentPath === '') {
			return true;
		}
		return !this.excludePages.filter((pagePath) =>
			pagePath.match(currentPath),
		).length;
	}

	// eslint-disable-next-line class-methods-use-this
	_getUserData() {
		return {
			userAgent: navigator.userAgent,
			sourcePage: window.location.pathname,
			sourceUrl: window.location.search,
			userTimeZone: moment(),
			currentDateTime: momenttz.tz.guess(),
		};
	}

	_connectSocket() {
		console.log(
			'Current Socket available before creating new socket connection?',
		);
		const { WS_BASE_URL } = host();
		this.socket = io.connect(WS_BASE_URL, {
			transports: ['websocket'],
			upgrade: false,
			query: {
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
			},
			reconnection: true,
		});
		this.socket.on('disconnected', () => {
			this.socket.emit('DISCONNECTED', { data: '12345' });
		});
		console.log('Connecting : ', WS_BASE_URL);
		this.socket.emit(
			'START_SESSION',
			JSON.stringify({
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
			}),
		);
		this.socket.on('reconnect', () => {
			const uid = getParams('uid');
			if (uid) {
				console.log('HEALTH CHECK::: Reconnecting : ', WS_BASE_URL);
			}
			this.socket.emit(
				'START_SESSION',
				JSON.stringify({
					...this._getUserData(),
					uid,
					sessionType: this.sessionType,
					reconnect: true,
				}),
			);
			// Re-fetch IP Address of client's system, in case of reconnection.
			const { handleIPAddress } = this.props;
			getIPAddress().then((ip) => {
				handleIPAddress(ip);
			});
		});
		this.socket.on('PING_EXPIRE_SESSION', (data) => {
			console.log(
				'HEALTH CHECK::: SOCKET Data received',
				JSON.stringify(data),
			);
			const uid = getParams('uid');
			const sessionId = getParams('session_id');
			if (uid === data.uid || sessionId === data.uid) {
				const currentStatus = get(
					data,
					'res.response.other_params.current_status',
					'',
				);
				const { pathname } = window.location;
				if (pathname && currentStatus) {
					let searchParam = '';
					if (uid) {
						searchParam = `?uid=${uid}`;
					} else {
						searchParam = `?session_id=${sessionId}`;
					}
					this.socket.emit(
						'PONG_EXPIRE_SESSION',
						JSON.stringify({
							uid: this.uid,
							searchParam,
							...this._getUserData(),
						}),
					);
					const { handleTimeoutResponse } = this.props;
					handleTimeoutResponse({
						data: data.res,
					});
				}
			}
		});
		this.socket.on('PING_ALERT_EXPIRE_SESSION', (data) => {
			const { handleAlertTimeout } = this.props;
			const uid = getParams('uid');
			const sessionId = getParams('session_id');
			
			if(uid === data.uid || sessionId === data.uid) {
				console.log(
					'HEALTH CHECK::: Pinging PING_ALERT_EXPIRE_SESSION Popup',
					JSON.stringify(data),
				);
				const popupMessage = 'Your session is about to timeout. Would you like to continue this session?';
				handleAlertTimeout({
					question: mockAlertPopup(popupMessage),
					overlayBgColor: 'rgba(255,255,255,0.5)',
					show: true,
					force: true,
					handleClick: this.handleAlertTimeoutContinue,
					hasBorder: true,
					label: 'Continue',
					type: MODALTYPES.alertTimeout,
				});	
			}
		});
		console.log('Creating socket connection.');
	}

	render() {
		const { children } = this.props;
		return children;
	}
}

const mapStateToProps = (state) => {
	return {
		uid: state.uid,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		handleTimeoutResponse: (response) => {
			const uid = get(response, 'data.response.other_params.uid', '');
			const currentStatus = get(
				response,
				'data.response.other_params.current_status',
				'',
			).toUpperCase();
			router(dispatch, uid, false, response);
			dispatch({ type: SET_ACTION_MESSAGE });
			dispatch({
				type: `GET_${currentStatus}_SUCCESS`,
				payload: get(response, 'data.response', null),
				params: {},
			});
		},
		handleAlertTimeout: (payload) => {
			dispatch(setPopupDetails(payload));
		},
		handleIPAddress: (payload) => {
			dispatch(setIPAddress(payload));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HealthCheck),
);
