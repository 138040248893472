/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle }  from 'styled-components';
import { Provider } from 'react-redux';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from './layout';
import configureStore, { history } from './redux/configureStore';
import initApp from './initApp';
import { resizeListener } from './utils';
import IdentityCheck from './identityCheck';
import HealthCheck from './healthCheck';
import { getParams } from './util';
import { mockInactiveSessionResponse } from './mockResponse';
import { getNextQuestionSet, getMockQuestionSet } from './redux/actions';
import SessionStorageServices from './util/sessionStorageServices';
import ErrorBoundary from './ErrorBoundary';

const store = configureStore();

const GlobalStyle = createGlobalStyle`
#root {
   font-family: ${({ globalFontFamily }) => globalFontFamily || 'Roboto'};
   ${({ globalFontColor }) => globalFontColor && `color: ${globalFontColor};`}
}
::selection {
	color: #33333;
	background-color: #ACCEF7;
}
`

const Main = ({ config, ssoAuthConfig, location }) => {
    const { pathname } = location;
    const initPageSkipRoutes = ['/ssodone/','/authError/'];
	const _resizeListener = useCallback(resizeListener.bind(window, store), []);
	
	useEffect(() => {
		if(!initPageSkipRoutes.includes(pathname))  initApp(store, config, ssoAuthConfig);

		window.addEventListener('resize', _resizeListener);
		return () => {
			window.removeEventListener('resize', _resizeListener);
		}
	}, [pathname]);

	useEffect(() => {
		return history.listen(() => {
		  const uid = getParams('uid');
		  const historyActions = ['PUSH', 'POP', 'REPLACE'];
		  const identity = SessionStorageServices.getIdentity(uid);
		  if (historyActions.indexOf(history.action) > -1) {
			if (identity === 'invalid') {
				store.dispatch(getMockQuestionSet(mockInactiveSessionResponse));
				return;
			} 
		  }
		  if (history.action === 'POP') {
			  if (uid === null) {
				  // this covers the case when user clicks on browser back button 
				  // at the landing page.
				  // https://sureify.atlassian.net/browse/NAT-1573
				window.history.back()
			  } 
			  else {
				store.dispatch(
					getNextQuestionSet('/questions', {
						uid,
					}, {
						reload: true,
					}))
			  }
			}
		})
	  }, [history])
	return (
		<Provider store={ store }>
			<Helmet>
				{/* <title>
					{get(config, 'global.appTitle', 'Acquire')}
				</title> */}
				<link rel="icon" type="image/png" href={ get(config, 'global.favicon', '') } />
				<link rel='stylesheet' type='text/css' href={ `/css/${process.env.REACT_APP_CARRIER_ID}.css` } />
			</Helmet>
			<HealthCheck>
				<ThemeProvider theme={ { config, ssoAuthConfig } }>
					<IdentityCheck>
						<ErrorBoundary>
							<GlobalStyle
								globalFontFamily={ config.theme.global.fontFamily }
								globalFontColor={ config.theme.global.fontColor }
							/>
							<Layout />
						</ErrorBoundary>
					</IdentityCheck>
				</ThemeProvider>
			</HealthCheck>
		</Provider>
	);
};

Main.propTypes = {
	config: PropTypes.any.isRequired,
};
export default withRouter(Main);
