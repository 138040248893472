/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'font-awesome/css/font-awesome.min.css';
import styled from 'styled-components';
import './footer.css';
import SocialLinks from './SocialLinks';
import FooterLinks from './FooterLinks';
import FooterNotes from './FooterNotes';
import FooterBottomLinks from './FooterBottomLinks';
import FooterTelephone from './FooterTelephone';
import FooterExpiry from './FooterExpiry';

const FooterRoot = styled.div`
z-index: 1;
border-top: 7px solid #e9e9e9;
background-color: ${props=> props.backgroundColor || 'rgba(59, 83, 105, 1)'};
color: ${props=> props.color};
${props => !props.removeFooterMargin && `padding-top: 20px;
  padding-bottom: 20px;`}
font-family: ${props => props.fontFamily || 'inherit' }
`;

const Img = styled.img`
  height: 59px;
  width: auto;
  max-width: 167px;
`;

const Bold = styled.p`
  font-weight: bold;
`;

const GlobalFooter = styled.div`
  ${props => !props.removeFooterMargin && 'padding: 50px 0;'}
  background: ${({ background }) => background};
  .footer-text-container {
    width: 71%;
  }
  .footer-links {
    color: #00465a;
  }
  .footer-links-container {
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container {
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    border-top: 1px solid #ddd;
    justify-content: space-between;
    a {
      cursor: default;
    }
  }
  .disclaimerContainer {
    font-family: ${(props) => props.fontFamily || 'inherit'};
    .disclaimer-desc{
      padding-top: 20px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding:0;
    .footer-links-container{
      width: 282.719px;
      justify-content: flex-start;
      padding: 32px 20px;
    }
    .container{
      padding-left: 0;
      width: 90%;
      flex-direction: column;
      .header-information-footer{
        padding-top: 0;
        padding-bottom: 56px;
      }
      .contact-number, .social-media-details, .footer-links-container, .disclaimer-desc{
        display: none;
      }
    }
  }
`;

const Partners = styled.div`
  margin: 20px 0;
`
const DisclaimerText = styled.div`
   padding: 20px;
   background-color: ${props => props.backgroundColor};
   color: ${props => props.color};
`;

function Footer({ footer, custCareTel, policyExpiry }) {
  const {
    color,
    background,
    link,
    altText,
    logo,
    phoneText,
    phoneNumber,
    footerLinksTop,
    socialLinks,
    disclaimerHead,
    disclaimerDesc,
    footerLinksBottom,
    isHTML,
    footerHTML,
    removeFooterMargin,
    showDisclaimerText,
    fontColor,
    disclaimerBackground,
  } = footer;
  return (
  <footer>
    <FooterRoot
      color={ color }
      name="FooterRoot"
      backgroundColor={ background }
      removeFooterMargin={ removeFooterMargin }
      fontFamily={ footer.fontFamily }
    >
      <GlobalFooter background={ background } removeFooterMargin={ removeFooterMargin } className="global-footer">
        {isHTML ? (
          <>
            {custCareTel && <FooterTelephone custCareTel={ custCareTel } />}
            <div
              className="container"
              dangerouslySetInnerHTML={ { __html: footerHTML.data } }
            />
            <FooterNotes />
            {policyExpiry && <FooterExpiry policyExpiry={ policyExpiry } />}
          </>
        ) : (
          <>
          <FooterLinks links={ footerLinksTop } />
          <div className="container">
            <div className="header-information-footer">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div className="company-logo" tabIndex={ 0 }>
              {
                link ? (
                  <a href={ link } target="_blank" rel="noopener noreferrer">
                    <Img src={ logo } alt={ altText } />
                  </a>
                ) : (
                  <img src={ logo } alt={ altText } style={ { height: '59px', width: 'auto', maxWidth: '167px' } }/>
                )
              }
              </div>
              <div className="contact-number">
                <div>
                  <strong>{`${phoneText} `}</strong>
                  {phoneNumber ? (
                    <a href={ `tel:${phoneNumber}` }>{`(${phoneNumber})`}</a>
                  ) : null}
                </div>
              </div>
            </div>
            <SocialLinks links={ socialLinks } />
            <div className="footer-text-container">
              {footer.businessPartners && (
                <Partners>
                  <span className="disclaimer-text">
                    {footer.businessPartners}
                  </span>
                  <FooterLinks links={ footer.footerBusinessLinks } />
                </Partners>
              )}
              <div className="disclaimerContainer">
                <p className="disclaimer-head">{disclaimerHead}</p>
                <p className="disclaimer-desc">{disclaimerDesc}</p>
              </div>
              {footer.footerTextBottom && (
                <Bold>{footer.footerTextBottom}</Bold>
              )}
              <FooterBottomLinks links={ footerLinksBottom } />
            </div>
          </div>
          </>
        )}
      </GlobalFooter>
      {showDisclaimerText && <DisclaimerText color={ fontColor } backgroundColor={ disclaimerBackground }>{disclaimerDesc}</DisclaimerText>}
    </FooterRoot>
  </footer>
  );
}

Footer.propTypes = {
  footer: PropTypes.object.isRequired,
};

export default Footer;
