import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme, ThemeProvider } from 'styled-components';
import Header from './header';
import Footer from '../Components/Footer/footer';
import Toast from '../cruxComponents/Toast';
import AppRouter from '../AppRouter';
import TooltipModal from '../cruxComponents/utils/TooltipModal';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	${({ background }) => `
		background: ${background}
	`}
`;

const Layout = ({ 
	theme, 
	loading, 
	currentPageSeqNumber, 
	screenViewport, 
	actionMessage, 
	popupDetails,
	activeSubsectionId,
	questionsLayout,
	custCareTel,
	policyExpiry,
}) => {
	const {
		config: {
			theme: { global },
			footer,
			layout,
			customHeader,
		},
	} = theme;

	const pageId = window.location.pathname.split('/')[1] || '';
	let pageLayout = pageId ? layout[pageId] : 'auto';
	if (loading && currentPageSeqNumber < 1) {
		pageLayout = 'single';
	}
	if (!layout[pageId]) {
		pageLayout = 'auto';
	}
	if (questionsLayout) {
		pageLayout = questionsLayout;
	}

	// Defines routes which doesn't includes header and footer
	const routesWithoutHeaderAndFooter = ['login', 'view-document'];

	// Adds toast message only if action message is of type success
	const hasSuccessActionMessage = actionMessage && actionMessage.type === 'success';
	const hasErrorActionMessage = actionMessage && actionMessage.type === 'error';


	return (
		<ThemeProvider theme={ { ...theme, pageId, pageLayout, screenViewport, activeSubsectionId } }>
			{hasSuccessActionMessage && <Toast type='success' message={ actionMessage.message } /> }
			{hasErrorActionMessage && <Toast type='error' message={ actionMessage.message } /> }
			<Body background={ global.background }>
				{routesWithoutHeaderAndFooter.includes(pageId) ? <AppRouter /> : 
				 <>
				 <Header 
					name='header'
					layout={ pageLayout } 
					background={ global.background } 
					screenViewport={ screenViewport } 
					loading={ loading }
					customHeader={  customHeader }					
				/>
				<AppRouter />
				<Footer
					custCareTel={ custCareTel } 
					policyExpiry={ policyExpiry }
					footer={ { ...footer, background: global.footerBackground, color: global.footerTextColor, removeFooterMargin: global.removeFooterMargin, showDisclaimerText: global.showDisclaimerText, fontColor: global.fontColor, disclaimerBackground: global.background } }
				/>
				{popupDetails.show && (
					<TooltipModal />
				)}
				</>
				}
			</Body>
		</ThemeProvider>
	);
};

Layout.propTypes = {
	theme: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ questions, meta }) => ({
	loading: questions.isLoading,
	actionMessage: questions.actionMessage,
	currentPageSeqNumber: questions.currentPageSeqNumber,
	screenViewport: meta.screenViewport,
	activeSubsectionId: questions.activeSubsectionId,
	questionsLayout: questions.layout,
	custCareTel:questions.custCareTel,
	policyExpiry:questions.policyExpiry,	
	popupDetails: get(questions, 'popupDetails', {}),
});

export default withTheme(connect(mapStateToProps, null)(Layout));
