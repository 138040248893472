/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import io from 'socket.io-client';
import get from 'lodash/get';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { setVisitorId } from '../redux/actions/meta';
import { SET_ACTION_MESSAGE } from '../redux/types';
import { getParams, getVisitorId } from '../util';
import host from '../util/host';
import router from '../util/router';
import LocalStorageServices from '../util/localStorageServices';
import SessionStorageServices from '../util/sessionStorageServices';

class IdentityCheck extends React.PureComponent {
	constructor(props) {
		super(props);
		this.uid = '';
		this.visitorId = '';
		this.userAgent = '';
        this.identityCheckInitiated = false;
		this._connectSocket = this._connectSocket.bind(this);
	}

	async componentDidMount() {
		const { uid, sessionId } = this._getIds();
		const { userAgent, handleSetVisitorId } = this.props;
		this.userAgent = userAgent;

		// Gets browser specific unique visitor identifier
		const existingVisitorId = LocalStorageServices.getVisitorId();
		if (!existingVisitorId) {
			this.visitorId = await getVisitorId();
			handleSetVisitorId(this.visitorId);
			LocalStorageServices.setVisitorId(this.visitorId);
		} else {
			this.visitorId = existingVisitorId;
			handleSetVisitorId(existingVisitorId);
		}

		if (!this.identityCheckInitiated && (uid || sessionId)) {
			this.uid = uid || sessionId;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
                this.identityCheckInitiated = true;
				console.log(
					'IDENTITY CHECK::: Initiating Identity Check in Component Did Mount.',
				);
				console.log('IDENTITY CHECK::: UID: ', this.uid);
			}
		}
	}

	componentDidUpdate() {
		const { uid, sessionId } = this._getIds();
		const { visitorId, userAgent } = this.props;
		this.visitorId = visitorId;
		this.userAgent = userAgent;

		if (!this.identityCheckInitiated && (uid || sessionId)) {
			this.uid = uid || sessionId;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
                this.identityCheckInitiated = true;
				console.log(
					'IDENTITY CHECK::: Initiating Identity Check in Component Did Update.',
				);
				console.log('IDENTITY CHECK::: UID: ', this.uid);
			}
		}
	}


	_getIds = () => {
		const { location } = window;
		let uid = '';
		let sessionId = '';
		if (location.search.match(/uid/)) {
			uid = getParams('uid');
		}
		if (location.search.match(/session_id/)) {
			sessionId = getParams('session_id');
		}
		return {
			uid,
			sessionId,
		};
	}

	_shouldStartSocketConnection() {
        const { hasIdentityCheckEnable } = this.props;

		// Checks if the identity is valid or not based on uid.
		const identity = SessionStorageServices.getIdentity(getParams('uid'));
		if (identity === 'invalid') {
			return false;
		}
        return hasIdentityCheckEnable;
	}

	// eslint-disable-next-line class-methods-use-this
	_getUserData() {
		return {
			userAgent: navigator.userAgent,
			sourcePage: window.location.pathname,
			sourceUrl: window.location.search,
			userTimeZone: moment(),
			currentDateTime: momenttz.tz.guess(),
		};
	}

	_connectSocket() {
		const { WS_BASE_URL } = host();
		this.socket = io.connect(WS_BASE_URL, {
            transports: ['websocket'],
            upgrade: false,
			query: {
				...this._getUserData(),
				uid: this.uid,
			},
			reconnection: true,
		});
		this.socket.emit(
			'START_IDENTITY_CHECK',
			JSON.stringify({
				...this._getUserData(),
				uid: this.uid,
				visitorId: this.visitorId || this.userAgent,
			}),
		);
        this.socket.on('reconnect', () => {
			const uid = getParams('uid');
			if (uid) {
				console.log('IDENTITY CHECK::: Reconnecting : ', WS_BASE_URL);
			}
			this.socket.emit(
				'START_IDENTITY_CHECK',
				JSON.stringify({
					...this._getUserData(),
					uid,
                    visitorId: this.visitorId || this.userAgent,
					reconnect: true,
				}),
			);
		});
		this.socket.on('PING_INACTIVE_SESSION', (data) => {
			console.log(
				'IDENTITY CHECK::: SOCKET Data received',
				JSON.stringify(data),
			);
			const uid = getParams('uid');
			const sessionId = getParams('session_id');
			const isDocumentRoute = window.location.pathname.indexOf('/view-document') > -1;
			
			if ((uid === data.uid || sessionId === data.uid) || isDocumentRoute) {
				const currentStatus = get(
					data,
					'res.response.other_params.current_status',
					'',
				);
				const { pathname } = window.location;
				if (pathname && currentStatus) {
					this.socket.emit(
						'PONG_INACTIVE_SESSION',
						JSON.stringify({
							uid: this.uid,
							...this._getUserData(),
						}),
					);
					const { handleInactiveSessionResponse } = this.props;
					handleInactiveSessionResponse({
						data: data.res,
					});
				}
				SessionStorageServices.setIdentity(uid, 'invalid');
			}
		});
	}

	render() {
		const { children } = this.props;
		return children;
	}
}

const mapStateToProps = (state, props) => {
	const visitorId = get(state, 'meta.visitorId', '');
	const userAgent = get(state, 'meta.userAgent', { name: '', version: '' });
    const hasIdentityCheckEnable = get(props, 'theme.config.features.identityCheck.enable', false);
	return {
		uid: state.uid,
		visitorId,
		userAgent: `${userAgent.name} v${userAgent.version}`,
        hasIdentityCheckEnable,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		handleSetVisitorId: (visitorId) => dispatch(setVisitorId(visitorId)),
		handleInactiveSessionResponse: (response) => {
			const uid = get(response, 'data.response.other_params.uid', '');
			const currentStatus = get(
				response,
				'data.response.other_params.current_status',
				'',
			).toUpperCase();
			router(dispatch, uid, false, response);
			dispatch({ type: SET_ACTION_MESSAGE });
			dispatch({
				type: `GET_${currentStatus}_SUCCESS`,
				payload: get(response, 'data.response', null),
				params: {},
			});
		},
	};
};

export default withTheme(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(IdentityCheck),
));
