const LocalStorageServices = (() => {
    const tokenObjName = 'jwtToken';
    const visitorIdObjName = 'visitorId';
    const ssoAccessToken = 'accessToken';


    const _getLocalStorageValue = (name) => {
       return localStorage.getItem(name);
    }
    const _setLocalStorageValue = (name,value) => {
       localStorage.setItem(`${name}`, value);;
     }

    const _getTokenItems = () => {
        const token = localStorage.getItem(tokenObjName);
        try {
            return JSON.parse(token)
        } catch (err) {
            return token
        }
    };

    const _setToken = (tokenObj) => {
        localStorage.setItem(tokenObjName, tokenObj);
    };

    const _getAccessToken = () => {
        const tokenItems = _getTokenItems();
        if(tokenItems){  
            return typeof tokenItems === 'string' ? tokenItems : tokenItems?.value;
        }
        return null;
    };
    const _getSsoAccessToken = () => {
        return localStorage.getItem(ssoAccessToken) || null;
    };

    const _getRefreshToken = () => {
        const tokenItems = _getTokenItems();
        return tokenItems ? tokenItems.refresh : null;
    };

    const _getTokenExpirationTime = () => {
        const tokenItems = _getTokenItems();
        return tokenItems ? tokenItems.expiry : null;
    };

    const _clearToken = () => {
        localStorage.removeItem(tokenObjName);
    };

    const _getVisitorId = () => {
        return localStorage.getItem(visitorIdObjName);
    }

    const _setVisitorId = (visitorId) => {
        localStorage.setItem(visitorIdObjName, visitorId);
    };

    return {
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getSSOAccessToken: _getSsoAccessToken,
        getRefreshToken: _getRefreshToken,
        getTokenExpirationTime: _getTokenExpirationTime,
        clearToken: _clearToken,
        getVisitorId: _getVisitorId,
        setVisitorId: _setVisitorId,
        setLocalStorageValue: _setLocalStorageValue,
        getLocalValue: _getLocalStorageValue,
    };
})();

export default LocalStorageServices;
