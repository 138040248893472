import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
// import '../node_modules/babel-polyfill';
import * as serviceWorker from './serviceWorker';
import log from './logger';
import Main from './Main';
import config from './constants/config';

log();
const { features:{ login } } = config;
const ssoAuthConfig = login?.ssoConfig || undefined;


if(config) {
	ReactDOM.render(
		<BrowserRouter>
			<Main config={ config } ssoAuthConfig={ ssoAuthConfig } />
		</BrowserRouter>,
		document.getElementById('root'),
	);
} else {
	window.location.href = '/page-not-found.html';
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
