import { executeAuthRequest } from '@sureifylabs/ext-frontend-identity';
import LocalStorageServices from './util/localStorageServices';


/**
 * @description Handles IDP/SP Initiated SSO login.
 * @param {Object} ssoAuthConfig
 */
export default async function ssoAuth( ssoAuthConfig, isFromCarrierPanel ) {
    const ssoAccessToken = LocalStorageServices.getSSOAccessToken();
	const { LIFETIME_AUTH_URL,SSO_USER_TYPE,SSO_REDIRECT_URL,IS_SSO_IDP_INITIATED_FLOW, ENABLE : ssoLoginEnable, CARRIER_PANEL_USER_TYPE } = ssoAuthConfig;
	console.debug(`ssologin enable ${ssoLoginEnable} --> ssoAccesstoken --> ${ssoAccessToken} ---> href -->${window.location.href}`);

		try {
			await executeAuthRequest(
				LIFETIME_AUTH_URL,
				// SSO_USER_TYPE,
				isFromCarrierPanel?CARRIER_PANEL_USER_TYPE:SSO_USER_TYPE,
				SSO_REDIRECT_URL,
				isFromCarrierPanel?false:IS_SSO_IDP_INITIATED_FLOW,
			);
		} catch (e) {
			console.error('There was an error while calling executeAuthRequest function');
			console.error(e);
		}

}