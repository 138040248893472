import React from 'react';
import styled from 'styled-components';
import NewQuestions from '../Questions/NewQuestions';

const Card = styled.div`
    display: flex;
    justify-content: center;
    background: white;
    transition: 0.3s;
    border-radius: 8px;
    margin: 5%;
    align-self: center;
    padding: 5%;
    @media(min-width: 720px) {
        padding: 10%;
    }
    @media(min-width: 420px) {
        white-space: nowrap;
    }
`;

const UnSubscribed = () => {
  return (
    <Card name="card">
      <NewQuestions />
    </Card>
  );
};

export default UnSubscribed;
